import '@tanstack/react-table'
import type { RowData } from '@tanstack/react-table'

declare module '@tanstack/react-table' {
  // biome-ignore lint/correctness/noUnusedVariables: declaration
  interface ColumnMeta<TData extends RowData, TValue> {
    className?: string
    dataTestId?: string
    dataTrackingId?: string
  }
}

export * from './background-image.ts'
export * from './border-radius.ts'
export * from './color.ts'
export * from './font.ts'
export * from './person.ts'
export * from './props.ts'
export * from './spacing.ts'
