'use client'

import { themeHoverBackgroundMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { type KeyboardEvent, forwardRef } from 'react'
import { Checkbox } from '../input/checkbox.tsx'
import { Text } from '../text/text.tsx'
import {
  type MultiselectOptionItem,
  useMultiselectContext,
} from './use-multiselect.ts'

type MultiselectOptionProps = {
  item: MultiselectOptionItem
  onClick?: () => void
}

const MultiselectOption = forwardRef<HTMLDivElement, MultiselectOptionProps>(
  ({ item, onClick }, ref) => {
    const { selectedItems } = useMultiselectContext()

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
      if (onClick && e.key === 'Space') {
        onClick()
      }
    }

    return (
      <div
        className={cn(
          'p-3 flex justify-start items-center gap-2.5 cursor-pointer',
          themeHoverBackgroundMap.tertiary
        )}
        onKeyDown={handleKeyDown}
        onClick={onClick}
        ref={ref}
      >
        <div className="grow-0 shrink-0 w-fit">
          <Checkbox
            value={
              !!selectedItems?.find(
                (selectedItem) => selectedItem.id === item.id
              )
            }
            className="pointer-events-none"
            onChange={onClick}
          />
        </div>
        <Text>{item.name}</Text>
      </div>
    )
  }
)

export { MultiselectOption }
