'use client'

import {
  type Virtualizer,
  useVirtualizer as useTanstackVirtualizer,
} from '@tanstack/react-virtual'
import type { Provider } from 'react'
import {
  createContext,
  getTypedProvider,
  getTypedRequiredUseContext,
} from '../utils/context.ts'

export type UseVirtualizerProps<
  ScrollElement extends Element,
  ItemElement extends Element,
> = Parameters<typeof useTanstackVirtualizer<ScrollElement, ItemElement>>[0]

type UseVirtualizerReturn<
  ScrollElement extends Element,
  ItemElement extends Element,
> = [
  Virtualizer<ScrollElement, ItemElement>,
  Provider<Virtualizer<ScrollElement, ItemElement>>,
  boolean,
]

export function useVirtualizer<
  ScrollElement extends Element,
  ItemElement extends Element,
>(props: UseVirtualizerProps<ScrollElement, ItemElement>) {
  return [
    useTanstackVirtualizer<ScrollElement, ItemElement>(props),
    getTypedProvider<Virtualizer<ScrollElement, ItemElement>>(
      UntypedVirtualizationProvider
    ),
    props.enabled ?? false,
  ] as UseVirtualizerReturn<ScrollElement, ItemElement>
}

export type VirtualItemProps = {
  index: number
}

export const getVirtualItemProps = (props: VirtualItemProps) => {
  return {
    'data-index': props.index,
  }
}

const [UntypedVirtualizationProvider, useUntypedVirtualizationContext] =
  createContext<UseVirtualizerReturn<Element, Element>>({
    name: 'VirtualizationContext',
  })

export function useVirtualizationContext<
  ScrollElement extends Element,
  ItemElement extends Element,
>() {
  return getTypedRequiredUseContext<
    UseVirtualizerReturn<ScrollElement, ItemElement>
  >(useUntypedVirtualizationContext)
}
